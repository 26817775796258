import React from 'react'
import PropTypes from 'prop-types'

import fbIcon from '../images/facebook.svg'
import twIcon from '../images/twitter.svg'
import lnIcon from '../images/linked-in.svg'

import './SocialsShareComponent.scss'

/**
 * SocialsShareComponent component
 *
 * @param {object} language
 * 
 * @returns {JSX Element}
 */
export default function SocialsShareComponent({ title, language, postSlugTranslationName, slug }) {

  // URL from live site
  const url = process.env.GATSBY_SHARE_URL;
  const shareUrl = `${url}/${postSlugTranslationName}${slug}`
  
  return (
    <div className="socials-share">
      <p className="socials-share__title strong">{language.locale === 'is_IS' ? 'Deila' : 'Share'}</p>
      <div className="socials-share__buttons">
        <div
          data-href={shareUrl}
          className="fb-share-button fb-custom"
          data-layout="button"
          data-size="small"
        >
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
            target="_blank"
            rel="noreferrer"
            className="fb-xfbml-parse-ignore"
          >
            <img src={fbIcon} alt="facebook icon" />
          </a> 
        </div>
        <a
          className="twitter-share-button"
          href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${title}`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={twIcon} alt="twitter icon" />
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${title}`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={lnIcon} alt="linkedin icon" />
        </a>
      </div>
    </div>
  )
}

SocialsShareComponent.propTypes = {
  language: PropTypes.object
}
