/**
 * Wrapper functon that adds timeout handling for fetch api
 * 
 * @param {function} fetchFunc 
 * @param {any} data 
 * @param {number} timeout 
 */
export const fetchWithTimeout = (fetchFunc, data, timeout) => {
  return Promise.race([
    fetchFunc(data),
    new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout))
  ]);
}

/**
 * Google Chrome doesn't support Icelandic locale
 * So this is custom function for display Icelandic date
 * @param {date} postDate
 */
export const getMonthName = postDate => {
  if (postDate) {
    const monthNames = [
      'janúar',
      'febrúar',
      'mars',
      'april',
      'maí',
      'júní',
      'july',
      'ágúst',
      'september',
      'október',
      'nóvember',
      'desember',
    ]
    const date = new Date(postDate),
      dateMonth = date.getMonth(),
      dateDay = date.getDate(),
      dateYear = date.getFullYear(),
      fullDate = `${dateDay}. ${monthNames[dateMonth]}, ${dateYear}`
    return fullDate
  }

  return null
}

